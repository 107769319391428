'use client'
import React, { useRef, useEffect, useState } from "react"

const endpoint = "https://generate.codingbutter.com"

export default function GeneratorForm() {
    const [uid, setUid] = useState<string|null>(null)
    const [isoLink, setIsoLink] = useState<string | null>(null)
    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [verifyPassword, setVerifyPassword] = useState<string>("")
    const [hostname, setHostname] = useState<string>("")
    const [ssh_keys, setSshKeys] = useState<string[]>([])
    const [ssh_key, setSshKey] = useState<string>("")
    const ssh_keyRef = useRef<HTMLTextAreaElement>(null)
    const passwordRef = useRef<HTMLInputElement>(null)
    const verifyPasswordRef = useRef<HTMLInputElement>(null)

    const addSSHKey = () => {
        if (ssh_key.length < 1) {
            ssh_keyRef.current?.setCustomValidity("SSH Key cannot be empty")
            ssh_keyRef.current?.reportValidity()
            return
        }
        if (ssh_keys.find((key) => key === ssh_key)) {
            ssh_keyRef.current?.setCustomValidity("SSH Key already exists")
            ssh_keyRef.current?.reportValidity()
            return
        }
        setSshKeys([...ssh_keys, ssh_key])
        setSshKey("")
    }

    const removeSSHKey = (index: number) => {
        setSshKeys(ssh_keys.filter((_, i) => i !== index))
    }

    const validatePassword = (report:boolean=false) => {
        
        //check if password is atleast 8 characters long and both passwords match
        if (password !== verifyPassword){
            passwordRef.current?.setCustomValidity("Passwords do not match")
            verifyPasswordRef.current?.setCustomValidity("Passwords do not match")
        }else {
            passwordRef.current?.setCustomValidity("")
            verifyPasswordRef.current?.setCustomValidity("")
        }
        if (report) {
            //verifyPasswordRef.current?.reportValidity()
        }
    }

    const generate = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const res = await fetch(`${endpoint}/ubuntu`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username,
                password,
                hostname,
                ssh_keys
            })
        })
        const { uid } = await res.json()
        setUid(uid)
    } 

    useEffect(() => {
        if (uid) {
            const interval = setInterval(async () => {
                const res = await fetch(`${endpoint}/status/${uid}`)
                const { status } = await res.json()
                if (status==="ready") {
                    setIsoLink(`${endpoint}/download/${uid}`)
                    clearInterval(interval)
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    },[uid])
    //Style a nice form with TailwindCSS
    return (
        <div className={`p-8 gap-4 flex flex-col items-center justify-center h-screen w-screen bg-gray-800 relative`}>
            <form onSubmit={generate}  className="flex flex-col gap-4 items-center justify-center h-full w-full p-12 text-center relative z-10">
                <h1 className="text-6xl text-white">Ubuntu Generator</h1>
                <h2 className="text-2xl text-white">Generate a custom Ubuntu ISO</h2>
                <div className="gap-4 flex flex-col items-center justify-center w-full">
                    <div className="flex items-center justify-center w-full gap-2">
                        <input className="flex-grow p-2 rounded-md bg-gray-700 text-white" type="text" value={hostname} onChange={e => setHostname(e.target.value)} placeholder="Hostname" required/>
                        <input className="flex-grow p-2 rounded-md bg-gray-700 text-white" type="text" value={username} onChange={e => setUsername(e.target.value)} placeholder="Username" required/>
                    </div>
                    <div className="flex items-center justify-center w-full gap-2">
                        <input ref={passwordRef} className="flex-grow p-2 rounded-md bg-gray-700 text-white" type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" required onBlur={()=>validatePassword()} />
                        <input ref={verifyPasswordRef} className="flex-grow p-2 rounded-md bg-gray-700 text-white" type="password" value={verifyPassword} onChange={e => setVerifyPassword(e.target.value)} placeholder="Verify Password" required onBlur={()=>validatePassword(true)}/>
                    </div>
                    <div className="flex flex-col gap-4 items-center justify-center w-full">
                        <label className="text-white text-xl">SSH Keys</label>
                        <div className="flex flex-col items-center justify-center w-full gap-2">
                            <textarea ref={ssh_keyRef} className="w-full flex-grow p-2 rounded-md bg-gray-700 text-white" value={ssh_key} onChange={e => {
                                e.target.setCustomValidity("")
                                setSshKey(e.target.value)
                            }} placeholder="rsa-key ...." />
                               <div className="flex items-end justify-between w-full gap-2">
                                <label className="text-gray-400">{ssh_keys.length > 0 && "SSH KEYS"}</label>
                                    <button className="flex-shrink p-2 rounded-md bg-gray-700 text-white" type="button" onClick={addSSHKey}>Add SSH Key</button>
                                </div>
                            </div>
                        <div className="flex flex-col items-center justify-center w-full gap-2">
                            {ssh_keys.map((key, index) => (
                                <div className="flex flex-row items-center justify-center w-full gap-2">
                                    <div className="w-full p-2 rounded-md bg-gray-700 text-white text-xs text-left whitespace-nowrap overflow-x-auto">{key}</div>
                                    <button className="flex-shrink whitespace-nowrap p-2 rounded-md bg-gray-700 text-white" type="button" onClick={() => removeSSHKey(index)}>Remove SSH Key</button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <input className="w-full p-2 rounded-md bg-gray-700 text-white" type="submit" value="Generate"/>
                </div>
            </form>
            {uid && (
                <dialog className="absolute z-20 top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-black/70">
                    {
                        isoLink ? <a rel="noreferrer" target="_blank" className="text-white text-2xl select-none rounded-sm shadow-md bg-gray-600 px-2 cursor-pointer hover:bg-gray-700" href={`${isoLink}?filename=ubuntu-server-autoinstall-${hostname}`}>Download ISO</a> : <h1 className="text-white text-2xl select-none rounded-sm shadow-md bg-gray-600 px-2">Generating ISO</h1>
                    }
                </dialog>
            )}
        </div>
    )

}