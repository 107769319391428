import * as Blocks from './blocks'

interface PageProps {
    page: Page
}
export default function Page({ page }: PageProps) {
    console.log(page.blocks)
    return (
        <div className="flex flex-col items-center justify-center bg-gray-800 w-full overflow-y-auto overflow-x-hidden">
            {page?.blocks?.map((block, index) => {
                // @ts-ignore
                const Block = Blocks[block.type]
                return Block && <Block key={index} data={block.data} />
            })}
        </div>
    )
}