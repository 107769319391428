import Page from "./components/Page";
import {usePage} from './hooks/useAPI';

function App() {
  const page = usePage("home")
  return (
    <>
      {page &&
        <Page page={page} />
      }
    </>
  )
}

export default App;
