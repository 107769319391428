import LazyImage from "../global/LazyImage";

type HeroData = Block & {
    title: string;
    subtitle: string;
    images: LazyImage
}

interface HeroProps {
    data: HeroData;
}

//Style with TailwindCSS
export default function Hero({ data: { title, subtitle, images } }: HeroProps) {
    const { large, thumb } = images
    return (
        <div className="flex flex-col items-center justify-center h-screen w-screen bg-gray-800 relative">
            <div className="h-full w-full absolute top-0 left-0">
                <LazyImage thumb={thumb as string} large={large as string} className="object-cover w-full h-full absolute top-0 left-0" />
                <div className="bg-gradient-to-b from-black/10 to-black/10 via-black/80 w-full h-full absolute top-0 left-0"></div>
            </div>
            <div className="h-full w-full flex justify-center items-center flex-col p-12 text-center relative z-10">
                <h1 className="text-6xl text-white" dangerouslySetInnerHTML={{__html: title}}></h1>
                <h2 className="text-2xl text-white" dangerouslySetInnerHTML={{__html: subtitle}}></h2>
            </div>
        </div>
    )
}