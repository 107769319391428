import { useState } from "react";
import classNames from "classnames";
interface LazyImageProps extends React.HTMLAttributes<HTMLDivElement> {
    large: string;
    thumb: string;
}
export default function LazyImage({ large, thumb, className}: LazyImageProps) {
    const [loaded, setLoaded] = useState(false)
    //create lazy loading images
    return <div className={classNames("w-full h-full bg-cover bg-center transition-all duration-500",loaded ? "blur-0":"blur-3xl",className)} style={{backgroundImage:`url(${thumb})`}}>
        <img onLoad={() => { console.log("loaded"); setLoaded(true) }} src={large} alt="large" className={classNames( "object-cover object-center w-full h-full transition-all duration-500 opacity-0 z-10", loaded && "opacity-100")}/>
    </div>
}